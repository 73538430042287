import PropTypes from 'prop-types';
import React, { Component } from 'react';
import View from 'spider/store/View';
import { Route as RouteDom, Switch, Redirect } from 'react-router-dom';
import Load from './Loadable';
import ClaimIncidentEdit from 'screen/Claim/Incident/Edit';
import * as Sentry from '@sentry/react';

const Route = Sentry.withSentryRouting(RouteDom);

const UserOverview = Load(() => import('../screen/UserOverview'));
const UserEdit = Load(() => import('../screen/UserEdit'));
const TachoEventOverview = Load(() => import('../screen/TachoEventOverview'));
const TruckBrandOverview = Load(() => import('../screen/TruckBrandOverview'));
const TruckBrandEdit = Load(() => import('../screen/TruckBrandEdit'));
const TruckOverview = Load(() => import('../screen/TruckOverview'));
const TruckEdit = Load(() => import('../screen/TruckEdit'));
const TrailerOverview = Load(() => import('../screen/TrailerOverview'));
const TrailerEdit = Load(() => import('../screen/TrailerEdit'));
const FleetvisorFormOverview = Load(() => import('../screen/FleetvisorFormOverview'));
const FleetvisorFormEdit = Load(() => import('../screen/FleetvisorFormEdit'));
const TransicsFormOverview = Load(() => import('../screen/TransicsFormOverview'));
const TransicsFormEdit = Load(() => import('../screen/TransicsFormEdit'));
const LisIntegrationOverview = Load(() => import('../screen/LisIntegration/Overview'));
const LisIntegrationEdit = Load(() => import('../screen/LisIntegration/Edit'));
const EcsIntegrationOverview = Load(() => import('../screen/EcsIntegration/Overview'));
const EcsIntegrationEdit = Load(() => import('../screen/EcsIntegration/Edit'));
const ActivitiesOverview = Load(() => import('../screen/ActivitiesOverview'));
const DriverOverview = Load(() => import('../screen/DriverOverview'));
const DriverEdit = Load(() => import('../screen/DriverEdit'));
const EntityOverview = Load(() => import('../screen/EntityOverview'));
const EntityEdit = Load(() => import('../screen/EntityEdit'));
const CustomerOverview = Load(() => import('../screen/CustomerOverview'));
const CustomerCreditOverview = Load(() => import('../screen/CustomerCreditOverview'));
const CustomerEdit = Load(() => import('../screen/CustomerEdit'));
const ContractEdit = Load(() => import('../screen/ContractEdit'));
const PlanningCustomer = Load(() => import('../screen/PlanningCustomer'));
const PlanningDispatcher = Load(() => import('../screen/PlanningDispatcher'));
const PlanningCsEmployee = Load(() => import('../screen/PlanningCsEmployee'));
const OperationAssetPlanning = Load(() => import('../screen/Operation/AssetPlanning'));
const OperationAssetPlanning2 = Load(() => import('../screen/Operation/AssetPlanning2'));
const BigMaps = Load(() => import('../screen/BigMaps'));
const PlanningMaps = Load(() => import('../screen/PlanningMaps'));
const SupplierOverview = Load(() => import('../screen/Supplier/Overview'));
const SupplierEdit = Load(() => import('../screen/Supplier/Edit'));
const LoginForgot = Load(() => import('../screen/LoginForgot'));
const ResetPassword = Load(() => import('spider/semantic-ui/ResetPassword'));
const AccountDetails = Load(() => import('../screen/AccountDetails'));
const AccountPassword = Load(() => import('../screen/AccountPassword'));
const NotFound = Load(() => import('../container/NotFound'));
const SystemHealth = Load(() => import('../screen/SystemHealth'));
const Changelog = Load(() => import('../screen/Changelog'));
const ChooseSubmenu = Load(() => import('../screen/ChooseSubmenu'));
const OwnBEPTicketOverview = Load(() => import('../screen/OwnBEPTicketOverview'));
const BEPTicketOverview = Load(() => import('../screen/BEPTicketOverview'));
const BEPTicketEdit = Load(() => import('../screen/BEPTicketEdit'));
const OwnBEPTicketEdit = Load(() => import('../screen/OwnBEPTicketEdit'));
const BEPTicketCategoryOverview = Load(() => import('../screen/BEPTicketCategoryOverview'));
const BEPTicketSubcategoryOverview = Load(() => import('../screen/BEPTicketSubcategory/Overview'));
const BEPTicketSubcategoryEdit = Load(() => import('../screen/BEPTicketSubcategory/Edit'));
const BEPTicketCategoryEdit = Load(() => import('../screen/BEPTicketCategoryEdit'));
const BEPFollowupActionCategoryOverview = Load(() => import('../screen/BEPFollowupActionCategoryOverview'));
const BEPFollowupActionCategoryEdit = Load(() => import('../screen/BEPFollowupActionCategoryEdit'));
const InvoiceOverview = Load(() => import('../screen/InvoiceOverview'));
const InvoiceAddActivity = Load(() => import('../screen/InvoiceAddActivity'));
const InvoiceApproveByCustomer = Load(() => import('../screen/Invoice/ApproveByCustomer'));
const DisputeOverview = Load(() => import('../screen/Dispute/Overview'));
const DisputeOperationsOverview = Load(() => import('../screen/Dispute/OperationsOverview'));
const DisputeCustomerOverview = Load(() => import('../screen/Dispute/CustomerOverview'));
const CustomerLogin = Load(() => import('../screen/Customer/Login'));
const InvoiceWaiverOverview = Load(() => import('../screen/InvoiceWaiverOverview'));
const CostOverview = Load(() => import('../screen/Cost/Overview'));
const CostEdit = Load(() => import('../screen/Cost/Edit'));
const EntityInvoiceOverview = Load(() => import('../screen/EntityInvoiceOverview'));
const EntityInvoiceAddInvoice = Load(() => import('../screen/EntityInvoiceAddInvoice'));
const EntityInvoiceAddLeaseContract = Load(() => import('../screen/EntityInvoiceAddLeaseContract'));
const CertificationEdit = Load(() => import('../screen/Certification/EditCertification'));
const OverviewCertification = Load(() => import('../screen/Certification/OverviewCertification'));
const DocumentRejectByCustomer = Load(() => import('../screen/Document/RejectByCustomer'));

const ParkingOverview = Load(() => import('../screen/Parking/Overview'));
const ParkingEdit = Load(() => import('../screen/Parking/Edit'));

const RestrictedZoneEdit = Load(() => import('../screen/RestrictedZone/Edit'));

const PetrolStationOverview = Load(() => import('../screen/PetrolStation/Overview'));
const PetrolStationEdit = Load(() => import('../screen/PetrolStation/Edit'));

const ExciseOverview = Load(() => import('../screen/Excise/ExciseOverview'));
const ExciseEdit = Load(() => import('../screen/Excise/ExciseEdit'));

const HomebaseOverview = Load(() => import('../screen/Homebase/Overview'));
const HomebaseEdit = Load(() => import('../screen/Homebase/Edit'));

const OverviewTollboxSuppliers = Load(() => import('../screen/TollboxSuppliers/OverviewTollboxSuppliers'));
const TollboxSuppliersEdit = Load(() => import('../screen/TollboxSuppliers/EditTollboxSuppliers'));

const OverviewLicenses = Load(() => import('../screen/Licenses/OverviewLicenses'));
const LicensesEdit = Load(() => import('../screen/Licenses/EditLicenses'));

const ConditionInstructionOverview = Load(() => import('../screen/ConditionInstruction/Overview'));
const ConditionInstructionEdit = Load(() => import('../screen/ConditionInstruction/Edit'));

const DietRuleEdit = Load(() => import('../screen/DietRule/DietRuleEdit'))

const OverviewDriverBonus = Load(() => import('../screen/DriverBonus/OverviewDriverBonus'));
const DriverBonusEdit = Load(() => import('../screen/DriverBonus/EditDriverBonus'));

const OverviewAssumption = Load(() => import('../screen/Assumption/AssumptionOverview'));

const OverviewRejectionReason = Load(() => import('../screen/RejectionReason/OverviewRejectionReason'));
const RejectionReasonEdit = Load(() => import('../screen/RejectionReason/EditRejectionReason'));

const EquipmentTypeOverview = Load(() => import('screen/EquipmentType/Overview'));
const EquipmentTypeEdit = Load(() => import('screen/EquipmentType/Edit'));

const CashRequestOverview = Load(() => import('../screen/CashRequest/Overview'));
const CashRequestEdit = Load(() => import('../screen/CashRequest/Edit'));

const PayrollCompanyOverview = Load(() => import('../screen/PayrollCompany/Overview'));
const PayrollCompanyEdit = Load(() => import('../screen/PayrollCompany/Edit'));

const ContractTypeOverview = Load(() => import('../screen/ContractType/OverviewContractType'));
const ContractTypeEdit = Load(() => import('../screen/ContractType/EditContractType'));

const MessageOverview = Load(() => import('../screen/Message/Overview'));
const TruckPositionOverview = Load(() => import('../screen/TruckPosition/Overview'));

const VerificationFormOverview = Load(() => import('../screen/Verification/Form/Overview'));
const VerificationFormVersionEdit = Load(() => import('../screen/Verification/FormVersion/Edit'));

const DriverCareManager = Load(() => import('../screen/DriverCare/Manager'));
const DriverCareInProgress = Load(() => import('../screen/DriverCare/InProgress'));
const DriverCareRecruitment = Load(() => import('../screen/DriverCare/Recruitment'));

const DriverCareLeadGenerationChannelOverview = Load(() => import('../screen/DriverCare/LeadGenerationChannel/Overview'));
const DriverCareLeadGenerationChannelEdit = Load(() => import('../screen/DriverCare/LeadGenerationChannel/Edit'));

const DriverCareLeadGenerationCampaignOverview = Load(() => import('../screen/DriverCare/LeadGenerationCampaign/Overview'));
const DriverCareLeadGenerationCampaignEdit = Load(() => import('../screen/DriverCare/LeadGenerationCampaign/Edit'));

const ClaimIncidentOverview = Load(() => import('screen/Claim/Incident/Overview'));

const FuelCostOverview = Load(() => import('screen/FuelCost/Overview'));
const FuelCostEdit = Load(() => import('screen/FuelCost/Edit'));

const RoadCostOverview = Load(() => import('screen/RoadCost/Overview'));
const RoadCostEdit = Load(() => import('screen/RoadCost/Edit'));

const CostSupplierOverview = Load(() => import('screen/CostSupplier/Overview'));
const CostSupplierEdit = Load(() => import('screen/CostSupplier/Edit'));

const CostInvoiceOverview = Load(() => import('screen/CostInvoice/Overview'));
const CostInvoiceEdit = Load(() => import('screen/CostInvoice/Edit'));

const CostProductOverview = Load(() => import('screen/CostProduct/Overview'));
const CostProductEdit = Load(() => import('screen/CostProduct/Edit'));

const LedgerCostOverview = Load(() => import('screen/LedgerCost/Overview'));
const LedgerCostEdit = Load(() => import('screen/LedgerCost/Edit'));

const LedgerVatOverview = Load(() => import('screen/LedgerVat/Overview'));
const LedgerVatEdit = Load(() => import('screen/LedgerVat/Edit'));

export default class Router extends Component {
    static propTypes = {
        store: PropTypes.instanceOf(View).isRequired,
    };

    // react-router is a bit too verbose so I made a shorthand
    route = Screen => {
        return rProps => <Screen {...rProps} viewStore={this.props.store} />;
    };

    redirectFromHome = () => {
        const user = this.props.store.currentUser;
        let uri = '/operation/activity-planning';
        // We don't use permissions here, but groups because the startpage can differ for the same set of permissions.
        if (user.inGroup(['asset planner'])) {
            uri = '/operation/asset-planning';
        }
        if (user.inGroup(['asset planner'])) { // XXX Why the same group twice?
            uri = '/operation/asset-planning';
        }
        if (
            user.inGroup([
                'customer service employee',
                'customer service manager',
                'financial'
            ])
        ) {
            uri = '/administration/invoice/overview';
        }
        if (user.inGroup(['human asset manager'])) {
            uri = '/masterdata/driver';
        }
        if (user.inGroup([
            'technical asset manager',
            'technical asset administrator'
            ])
        ) {
            uri = '/masterdata/truck';
        }
        if (user.inGroup(['customer administration'])) {
            uri = '/customer/dispute/overview/'
        }
        if (user.inGroup(['recruitment employee'])) {
            uri = '/driver-care/recruitment/'
        }
        return <Redirect to={uri} />;
    };

    render() {

        return (
            <Switch>
                <Route exact path="/" render={this.redirectFromHome} />

                <Route path="/maps" exact render={this.route(BigMaps)}/>

                <Route path="/operation/activity-planning/teams/:allocation" render={this.route(PlanningCustomer)} exact/>
                <Route path="/operation/activity-planning/teams/:allocation/:column" render={this.route(PlanningCustomer)} />

                <Route path="/operation/activity-planning" render={this.route(PlanningCustomer)} exact />
                <Route path="/operation/activity-planning/:allocation" render={this.route(PlanningCustomer)} exact/>
                <Route path="/operation/activity-planning/:allocation/:column" render={this.route(PlanningCustomer)} />

                <Route path="/operation/asset-planning2" exact render={this.route(OperationAssetPlanning)}/>
                <Route path="/operation/asset-planning2/allocation/:allocation/assignment/:assignmentId" render={this.route(OperationAssetPlanning)}/>
                <Route path="/operation/asset-planning2/allocation/:allocation/reassignmentChange/:reassignmentChangeId" render={this.route(OperationAssetPlanning)}/>
                <Route path="/operation/asset-planning2/allocation/:allocation/reassignmentTruckswap/:reassignmentTruckswapId" render={this.route(OperationAssetPlanning)}/>

                <Route path="/operation/asset-planning" exact render={this.route(OperationAssetPlanning2)}/>

                <Route path="/operation/parking/overview" render={this.route(ParkingOverview)} />
                <Route path="/operation/parking/:id/edit" render={this.route(ParkingEdit)} />
                <Route path="/operation/parking/add" render={this.route(ParkingEdit)} />

                <Route path="/operation/restricted-zone/:id/edit" render={this.route(RestrictedZoneEdit)} />
                <Route path="/operation/restricted-zone/add" render={this.route(RestrictedZoneEdit)} />

                <Route path="/operation/dispatcher" exact render={this.route(PlanningDispatcher)}/>
                <Route path="/operation/maps" exact render={this.route(PlanningMaps)}/>
                <Route path="/public/document/reject/:uuid" exact render={this.route(DocumentRejectByCustomer)}/>

                <Route path="/other/operation-dispute/overview" exact render={this.route(DisputeOperationsOverview)}/>
                <Route path="/other/own-bep-tickets/overview" render={this.route(OwnBEPTicketOverview)} />
                <Route path="/other/own-bep-tickets/:id/edit" render={this.route(OwnBEPTicketEdit)} />

                {/* related to disputes */}
                <Route path="/public/customer/login/:token" exact render={this.route(CustomerLogin)}/>
                <Route path="/customer/dispute/overview" exact render={this.route(DisputeCustomerOverview)}/>

                {/* TODO: Remove waiver, it is unused */}
                <Route path="/administration/waiver/overview" exact render={this.route(InvoiceWaiverOverview)}/>
                <Route path="/administration/invoice/overview" exact render={this.route(InvoiceOverview)}/>
                <Route path="/administration/credit/overview" exact render={this.route(CustomerCreditOverview)}/>
                <Route path="/administration/dispute/overview" exact render={this.route(DisputeOverview)}/>
                <Route path="/administration/cost/overview" exact render={this.route(CostOverview)}/>
                <Route path="/administration/cost/:id/edit" exact render={this.route(CostEdit)}/>
                <Route path="/administration/cost/add" exact render={this.route(CostEdit)}/>

                <Route path="/administration/entity-invoices" exact render={this.route(EntityInvoiceOverview)}/>
                <Route path="/administration/entity-invoice/add-by-entity/:entityFromId/invoices" exact render={this.route(EntityInvoiceAddInvoice)}/>
                <Route path="/administration/entity-invoice/add-by-entity/:entityToId/lease contracts" exact render={this.route(EntityInvoiceAddLeaseContract)}/>
                <Route path="/administration/entity-invoice/:id/invoices/edit" exact render={this.route(EntityInvoiceAddInvoice)}/>
                <Route path="/administration/entity-invoice/:id/lease contracts/edit" exact render={this.route(EntityInvoiceAddLeaseContract)}/>

                <Route path="/administration/csemployee" exact render={this.route(PlanningCsEmployee)}/>
                <Route path="/administration/activities" render={this.route(ActivitiesOverview)}/>

                <Route path="/administration/invoice/add/:contractId" exact render={this.route(InvoiceAddActivity)}/>
                <Route path="/administration/invoice/:id/edit" exact render={this.route(InvoiceAddActivity)}/>
                <Route path="/administration/invoice/:id/approve/:uuid" exact render={this.route(InvoiceApproveByCustomer)}/>

                <Route path="/administration/bep-tickets" exact render={this.route(BEPTicketOverview)}/>
                <Route path="/administration/bep-ticket/add" exact render={this.route(BEPTicketEdit)}/>
                <Route path="/administration/bep-ticket/:id/edit" exact render={this.route(BEPTicketEdit)}/>

                <Route path="/administration/fuel-cost/overview" render={this.route(FuelCostOverview)}/>
                <Route path="/administration/fuel-cost/add" render={this.route(FuelCostEdit)}/>
                <Route path="/administration/fuel-cost/:id/edit" render={this.route(FuelCostEdit)}/>

                <Route path="/administration/road-cost/overview" render={this.route(RoadCostOverview)}/>
                <Route path="/administration/road-cost/add" render={this.route(RoadCostEdit)}/>
                <Route path="/administration/road-cost/:id/edit" render={this.route(RoadCostEdit)}/>

                <Route path="/administration/cost-invoice/overview" render={this.route(CostInvoiceOverview)}/>
                <Route path="/administration/cost-invoice/add" render={this.route(CostInvoiceEdit)}/>
                <Route path="/administration/cost-invoice/:id/edit" render={this.route(CostInvoiceEdit)}/>

                <Route path="/masterdata/" exact render={this.route(ChooseSubmenu)}/>

                <Route path="/masterdata/driver" exact render={this.route(DriverOverview)}/>
                <Route path="/masterdata/driver/add" render={this.route(DriverEdit)}/>
                <Route path="/masterdata/driver/:id/edit" render={this.route(DriverEdit)}/>

                <Route path="/masterdata/customer" exact render={this.route(CustomerOverview)}/>
                <Route path="/masterdata/customer/add" render={this.route(CustomerEdit)}/>
                <Route path="/masterdata/customer/:id/edit" render={this.route(CustomerEdit)}/>
                <Route path="/masterdata/customer/:customerId/contract/add" render={this.route(ContractEdit)}/>
                <Route path="/masterdata/customer/:customerId/contract/:contractId/edit" render={this.route(ContractEdit)}/>

                <Route path="/masterdata/truck" exact render={this.route(TruckOverview)}/>
                <Route path="/masterdata/truck/add" render={this.route(TruckEdit)}/>
                <Route path="/masterdata/truck/:id/edit" render={this.route(TruckEdit)}/>

                <Route path="/masterdata/trailer" exact render={this.route(TrailerOverview)}/>
                <Route path="/masterdata/trailer/add" render={this.route(TrailerEdit)}/>
                <Route path="/masterdata/trailer/:id/edit" render={this.route(TrailerEdit)}/>

                <Route path="/masterdata/supplier" exact render={this.route(SupplierOverview)}/>
                <Route path="/masterdata/supplier/add" render={this.route(SupplierEdit)}/>
                <Route path="/masterdata/supplier/:id/edit" render={this.route(SupplierEdit)}/>

                <Route path="/masterdata/fleetvisor-form" exact render={this.route(FleetvisorFormOverview)}/>
                <Route path="/masterdata/fleetvisor-form/:id/edit" render={this.route(FleetvisorFormEdit)}/>
                <Route path="/masterdata/fleetvisor-form/add" render={this.route(FleetvisorFormEdit)}/>

                <Route path="/masterdata/excise/overview" exact render={this.route(ExciseOverview)}/>
                <Route path="/masterdata/excise/:country/edit" render={this.route(ExciseEdit)}/>
                <Route path="/masterdata/excise/add" render={this.route(ExciseEdit)}/>

                <Route path="/masterdata/transics-form" exact render={this.route(TransicsFormOverview)}/>
                <Route path="/masterdata/transics-form/:id/edit" render={this.route(TransicsFormEdit)}/>
                <Route path="/masterdata/transics-form/add" render={this.route(TransicsFormEdit)}/>

                <Route path="/masterdata/petrol-station/overview" render={this.route(PetrolStationOverview)} />
                <Route path="/masterdata/petrol-station/:id/edit" render={this.route(PetrolStationEdit)} />
                <Route path="/masterdata/petrol-station/add" render={this.route(PetrolStationEdit)} />

                <Route path="/admin/homebase/overview" render={this.route(HomebaseOverview)} />
                <Route path="/admin/homebase/:id/edit" render={this.route(HomebaseEdit)} />
                <Route path="/admin/homebase/add" render={this.route(HomebaseEdit)} />

                <Route path="/masterdata/conditionInstruction/overview" render={this.route(ConditionInstructionOverview)} />
                <Route path="/masterdata/conditionInstruction/add" render={this.route(ConditionInstructionEdit)} />
                <Route path="/masterdata/conditionInstruction/:condition/edit" render={this.route(ConditionInstructionEdit)} />

                <Route path="/masterdata/equipment-type/overview" render={this.route(EquipmentTypeOverview)} />
                <Route path="/masterdata/equipment-type/add" render={this.route(EquipmentTypeEdit)} />
                <Route path="/masterdata/equipment-type/:condition/edit" render={this.route(EquipmentTypeEdit)} />

                <Route path="/masterdata/bep-ticket-categories" exact render={this.route(BEPTicketCategoryOverview)}/>
                <Route path="/masterdata/bep-ticket-category/add" exact render={this.route(BEPTicketCategoryEdit)}/>
                <Route path="/masterdata/bep-ticket-category/:id/edit" exact render={this.route(BEPTicketCategoryEdit)}/>

                <Route path="/masterdata/bep-ticket-subcategory" exact render={this.route(BEPTicketSubcategoryOverview)}/>
                <Route path="/masterdata/bep-ticket-subcategory/add" render={this.route(BEPTicketSubcategoryEdit)}/>
                <Route path="/masterdata/bep-ticket-subcategory/:id/edit" render={this.route(BEPTicketSubcategoryEdit)}/>

                <Route path="/masterdata/bep-followup-action-categories" exact render={this.route(BEPFollowupActionCategoryOverview)}/>
                <Route path="/masterdata/bep-followup-action-category/add" exact render={this.route(BEPFollowupActionCategoryEdit)}/>
                <Route path="/masterdata/bep-followup-action-category/:id/edit" exact render={this.route(BEPFollowupActionCategoryEdit)}/>

                <Route path="/archive/activities" render={this.route(ActivitiesOverview)}/>
                <Route path="/archive/messages" render={this.route(MessageOverview)}/>
                <Route path="/archive/truck-positions" render={this.route(TruckPositionOverview)}/>

                <Route path="/admin/" exact render={this.route(ChooseSubmenu)}/>

                <Route path="/admin/user" exact render={this.route(UserOverview)}/>
                <Route path="/admin/user/add" render={this.route(UserEdit)} />
                <Route path="/admin/user/:id/edit" render={this.route(UserEdit)}/>

                <Route path="/admin/tacho-event/overview" exact render={this.route(TachoEventOverview)}/>

                <Route path="/admin/truck-brand" exact render={this.route(TruckBrandOverview)}/>
                <Route path="/admin/truck-brand/add" render={this.route(TruckBrandEdit)}/>
                <Route path="/admin/truck-brand/:id/edit" render={this.route(TruckBrandEdit)}/>

                <Route path="/admin/certification/overview" render={this.route(OverviewCertification)} />
                <Route path="/admin/certification/:id/edit" render={this.route(CertificationEdit)} />
                <Route path="/admin/certification/add" render={this.route(CertificationEdit)} />

                <Route path="/admin/tollbox-supplier/overview" render={this.route(OverviewTollboxSuppliers)} />
                <Route path="/admin/tollbox-supplier/:id/edit" render={this.route(TollboxSuppliersEdit)} />
                <Route path="/admin/tollbox-supplier/add" render={this.route(TollboxSuppliersEdit)} />

                <Route path="/admin/license/overview" render={this.route(OverviewLicenses)} />
                <Route path="/admin/license/:id/edit" render={this.route(LicensesEdit)} />
                <Route path="/admin/license/add" render={this.route(LicensesEdit)} />

                <Route path="/admin/cash-request/overview" render={this.route(CashRequestOverview)} />
                <Route path="/admin/cash-request/add" render={this.route(CashRequestEdit)} />
                <Route path="/admin/cash-request/:condition/edit" render={this.route(CashRequestEdit)} />

                <Route path="/admin/entity" exact render={this.route(EntityOverview)}/>
                <Route path="/admin/entity/add" render={this.route(EntityEdit)}/>
                <Route path="/admin/entity/:id/edit" render={this.route(EntityEdit)}/>

                <Route path="/admin/payroll-company/overview" exact render={this.route(PayrollCompanyOverview)}/>
                <Route path="/admin/payroll-company/add" exact render={this.route(PayrollCompanyEdit)}/>
                <Route path="/admin/payroll-company/:id/edit" exact render={this.route(PayrollCompanyEdit)}/>

                <Route path="/admin/contract-type/overview" render={this.route(ContractTypeOverview)} />
                <Route path="/admin/contract-type/:id/edit" render={this.route(ContractTypeEdit)} />
                <Route path="/admin/contract-type/add" render={this.route(ContractTypeEdit)} />

                <Route path="/verification-form/overview" render={this.route(VerificationFormOverview)} />
                <Route path="/verification-form-version/add" render={this.route(VerificationFormVersionEdit)} />
                <Route path="/verification-form-version/:id/edit" render={this.route(VerificationFormVersionEdit)} />

                <Route path="/driver-care/recruitment" render={this.route(DriverCareRecruitment)} />
                <Route path="/driver-care/in-progress" render={this.route(DriverCareInProgress)} />
                <Route path="/driver-care/manager" render={this.route(DriverCareManager)} />

                <Route path="/admin/payroll-company/:payrollCompanyId/diet-rule/:dietRuleId/edit" render={this.route(DietRuleEdit)} />
                <Route path="/admin/payroll-company/:payrollCompanyId/diet-rule/add" render={this.route(DietRuleEdit)} />

                <Route path="/masterdata/driver-bonus/overview" render={this.route(OverviewDriverBonus)} />
                <Route path="/masterdata/driver-bonus/:id/edit" render={this.route(DriverBonusEdit)} />
                <Route path="/masterdata/driver-bonus/add" render={this.route(DriverBonusEdit)} />

                <Route path="/masterdata/assumption" render={this.route(OverviewAssumption)} />

                <Route path="/masterdata/rejection-reason/overview" render={this.route(OverviewRejectionReason)} />
                <Route path="/masterdata/rejection-reason/:id/edit" render={this.route(RejectionReasonEdit)} />
                <Route path="/masterdata/rejection-reason/add" render={this.route(RejectionReasonEdit)} />

                <Route path="/driver-care/incident/overview" render={this.route(ClaimIncidentOverview)} />
                <Route path="/driver-care/incident/add" render={this.route(ClaimIncidentEdit)} />
                <Route path="/driver-care/incident/:id/edit" render={this.route(ClaimIncidentEdit)} />

                <Route path="/admin/lead-generation-channel/overview" render={this.route(DriverCareLeadGenerationChannelOverview)} />
                <Route path="/admin/lead-generation-channel/add" render={this.route(DriverCareLeadGenerationChannelEdit)} />
                <Route path="/admin/lead-generation-channel/:id/edit" render={this.route(DriverCareLeadGenerationChannelEdit)} />

                <Route path="/masterdata/lead-generation-campaign/overview" render={this.route(DriverCareLeadGenerationCampaignOverview)} />
                <Route path="/masterdata/lead-generation-campaign/add" render={this.route(DriverCareLeadGenerationCampaignEdit)} />
                <Route path="/masterdata/lead-generation-campaign/:id/edit" render={this.route(DriverCareLeadGenerationCampaignEdit)} />

                <Route path="/masterdata/cost-supplier/overview" render={this.route(CostSupplierOverview)} />
                <Route path="/masterdata/cost-supplier/add" render={this.route(CostSupplierEdit)} />
                <Route path="/masterdata/cost-supplier/:id/edit" render={this.route(CostSupplierEdit)} />

                <Route path="/masterdata/cost-product/overview" render={this.route(CostProductOverview)} />
                <Route path="/masterdata/cost-product/add" render={this.route(CostProductEdit)} />
                <Route path="/masterdata/cost-product/:id/edit" render={this.route(CostProductEdit)} />

                <Route path="/masterdata/ledger-cost/overview" render={this.route(LedgerCostOverview)} />
                <Route path="/masterdata/ledger-cost/add" render={this.route(LedgerCostEdit)} />
                <Route path="/masterdata/ledger-cost/:id/edit" render={this.route(LedgerCostEdit)} />

                <Route path="/masterdata/ledger-vat/overview" render={this.route(LedgerVatOverview)} />
                <Route path="/masterdata/ledger-vat/add" render={this.route(LedgerVatEdit)} />
                <Route path="/masterdata/ledger-vat/:id/edit" render={this.route(LedgerVatEdit)} />

                <Route path="/masterdata/lis-integration/overview" render={this.route(LisIntegrationOverview)} />
                <Route path="/masterdata/lis-integration/add" render={this.route(LisIntegrationEdit)} />
                <Route path="/masterdata/lis-integration/:id/edit" render={this.route(LisIntegrationEdit)} />

                <Route path="/masterdata/ecs-integration/overview" render={this.route(EcsIntegrationOverview)} />
                <Route path="/masterdata/ecs-integration/add" render={this.route(EcsIntegrationEdit)} />
                <Route path="/masterdata/ecs-integration/:id/edit" render={this.route(EcsIntegrationEdit)} />

                <Route path="/account/system-health" render={this.route(SystemHealth)}/>
                <Route path="/account/changelog" render={this.route(Changelog)}/>
                <Route path="/account/details" render={this.route(AccountDetails)}/>
                <Route path="/account/password" render={this.route(AccountPassword)}/>
                <Route path="/login/forgot" render={this.route(LoginForgot)} />
                <Route path="/user/:id/reset-password/:token" render={this.route(ResetPassword)} />

                <Route render={this.route(NotFound)} />
            </Switch>
        );
    }
}
